.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.round-image{
    object-fit: cover;
    border-radius: 50%;
}

.user-details-grid{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 1rem;
}

@media (min-width: 768px){
    .user-details-grid{
        grid-template-columns: repeat(4,1fr);
    }
}


@import "./assets/scss/BlockViewer.scss";
@import "./assets/scss/Documentation.scss";
@import "./assets/scss/MediaDemo.scss";
@import "./assets/scss/TableDemo.scss";
@import "./assets/scss/TimelineDemo.scss";
@import "./assets/scss/Badge.scss";
@import "./assets/scss/custom.scss";



#root > div.layout-wrapper.layout-static.layout-theme-light > div.layout-main-container > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) > div.recharts-responsive-container > div > div > div > ul > li > span.recharts-tooltip-item-name,
#root > div.layout-wrapper.layout-static.layout-theme-light > div.layout-main-container > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) > div.recharts-responsive-container > div > div > div > ul > li > span.recharts-tooltip-item-separator{
  display: none !important;
}

.p-inputswitch-slider, 
.p-inputswitch-slider::before{
  border-radius: 2rem !important;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: #007bff ;
    border-color: #007bff ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #007bff ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #007bff ;
    border-color: #007bff;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #007bff
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }

  #root > div.layout-wrapper.layout-static.layout-theme-light > div.layout-main-container > div > div.card > div.p-datatable.p-component.p-datatable-responsive-scroll > div > table > thead > tr:nth-child(2){
    display: none;
  }


  .grey{
    background-color: grey !important;
    cursor: not-allowed;
  }